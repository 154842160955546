import React, { useEffect, useState } from 'react'
import { Link, withRouter } from 'react-router-dom'

import AddIcon from '@material-ui/icons/Add'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import Fab from '@material-ui/core/Fab'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import { initAuth, authenticate, signOut } from '../../authentication.js'
import Spinner from '../../spinner.jsx'
import FullScreenSignIn from '../../sign_in'

const ListItemLink = (props) => <ListItem button component="a" {...props} />

const Index = (props) => {
  useEffect(() => {
    document.title = '文書一覧: カケラの樹'
  })

  const style = makeStyles((theme) => ({
    fab: {
      margin: 0,
      top: 'auto',
      left: 'auto',
      bottom: 20,
      right: 20,
      position: 'fixed',
    },
    pager: {
      textAlign: 'center',
    },
    title: {
      flexGrow: 1,
    },
  }))()

  let [signedIn, setSignedIn] = useState(false)
  let [documents, setDocuments] = useState(null)
  let [nextPageToken, setNextPageToken] = useState(null)
  const [authLoaded, setAuthLoaded] = useState(false)
  const [isLoaded, setIsLoaded] = useState(true)

  const listDocuments = async () => {
    setIsLoaded(false)

    const response = await gapi.client.drive.files.list({
      q: "mimeType='text/html' and trashed=false",
      fields: 'nextPageToken, files(id, name)',
      orderBy: 'modifiedTime desc',
      pageToken: nextPageToken,
    })

    setIsLoaded(true)
    const files = response.result.files
    if (files && files.length > 0) {
      setDocuments([...(documents || []), ...files])
      setNextPageToken(response.result.nextPageToken)
    }
  }

  useEffect(async () => {
    setSignedIn(await initAuth())
    setAuthLoaded(true)
  }, [])

  useEffect(() => {
    if (signedIn && documents == null) listDocuments()
  }, [signedIn])

  return (
    <div>
      <CssBaseline />
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className={style.title}>
            文書一覧
          </Typography>
          {signedIn && (
            <div>
              <Button
                color="inherit"
                onClick={() => {
                  signOut()
                  location.href = '/'
                }}
              >
                <Icon>logout</Icon>
              </Button>
            </div>
          )}
        </Toolbar>
      </AppBar>
      {signedIn && isLoaded && (
        <div>
          <List component="nav">
            {documents &&
              documents.map((d) => (
                <ListItemLink href={'./documents/' + d.id} key={d.id}>
                  <ListItemText primary={d.name} />
                </ListItemLink>
              ))}
          </List>
          {nextPageToken && (
            <div className={style.pager}>
              <Button onClick={listDocuments}>
                <Icon>expand_more</Icon>
              </Button>
            </div>
          )}
          <Fab
            color="primary"
            aria-label="add"
            className={style.fab}
            onClick={() => {
              location.href = './documents/new'
            }}
          >
            <AddIcon />
          </Fab>
        </div>
      )}

      {!signedIn && authLoaded && (
        <FullScreenSignIn
          onClick={async () => {
            if (await authenticate()) setSignedIn(true)
          }}
        />
      )}

      {(!isLoaded || !authLoaded) && <Spinner />}
    </div>
  )
}

export default withRouter(Index)

import { combineReducers, configureStore } from '@reduxjs/toolkit'

import { editorSlice } from './editorSlice'
import { documentSlice } from './documentSlice'

const reducer = combineReducers({
  editor: editorSlice.reducer,
  document: documentSlice.reducer,
})

export const store = configureStore({
  reducer,
})

import { getMetadata, save as fileSave } from '../models/file.js'

// 現在保持しているバージョンとDrive側との整合性チェック
const isValidVersion = async (version, fileId) => {
  const m = await getMetadata(fileId)
  const driveVersion = m.appProperties?.version
  if (!driveVersion || version == driveVersion) {
    // メタデータにバージョンが書かれていない場合は通す
    return true
  } else {
    console.log('conflicted: drive: ' + driveVersion + ' local: ' + version)
    return false
  }
}

let buffer = null
let changed = false
let saving = false
let currentVersion = null
// 書き込みバッファを更新する
// 更新された場合trueを返す
export const bufferedWrite = (doc) => {
  if (buffer == null) {
    //初回投入
    buffer = doc
    currentVersion = doc.version
  } else {
    if (
      JSON.stringify({ ...buffer, ...{ version: null } }) !=
        JSON.stringify({ ...doc, ...{ version: null } }) &&
      !changed
    ) {
      changed = true
      buffer = doc
      if (!saving) {
        return true
      }
    }
  }

  return false
}

export const isCurrentVersionValid = async (fileId) => {
  return await isValidVersion(currentVersion, fileId)
}

// 保存処理
const save = async (doc, fileId) => {
  const newVersion = new Date().valueOf()
  doc.version = newVersion

  try {
    if (await isValidVersion(currentVersion, fileId)) {
      await fileSave(doc, fileId)
      currentVersion = newVersion
      return doc
    } else {
      throw 'conflicted'
    }
  } catch (e) {
    console.log(e)
    if (e.status == 401) {
      // トークン期限切れ
      this.onConflict()
    } else {
      throw e
    }
  }
}

export const saveTimerStart = (params) => {
  const { fileId, onWrite, onWrote, onConflict } = params

  setInterval(async () => {
    if (changed && !saving) {
      // 文書に変更があり、保存処理中でなければ実行する
      changed = false // 変更フラグは保存開始時に下げないと保存中に変更が入った場合を扱えない
      saving = true
      onWrite()

      try {
        await save(buffer, fileId)
        // 保存成功
        saving = false
        if (!changed) {
          onWrote()
        }
      } catch (e) {
        console.log(e)
        // 保存失敗
        saving = false
        // リロード処理を掛ける必要がある
        onConflict()
      }
    }
  }, 10 * 1000)
}

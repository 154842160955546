import { googleApiCredentials } from './credentials.js'
import StackdriverErrorReporter from 'stackdriver-errors-js'

const errorHandler = new StackdriverErrorReporter()
if (location.hostname !== 'localhost') {
  errorHandler.start({
    key: googleApiCredentials.stackdriver.apiKey,
    projectId: googleApiCredentials.stackdriver.projectId,
  })
}

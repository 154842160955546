export const googleApiCredentials =
  process.env.NODE_ENV == 'development'
    ? {
        drive: {
          apiKey: 'AIzaSyCXyACeR6iULHXcC7atvhEslv-rR4HRv10',
          clientId:
            '930063073002-67e1alb1dgcn2b13mfuhn96bivqik6ni.apps.googleusercontent.com',
        },
        stackdriver: {
          apiKey: 'AIzaSyC2yhrEBW2nhO14J1-61lMGZ8QcZBU0r34',
          projectId: 'kakera-yoichi',
        },
      }
    : {
        drive: {
          apiKey: 'AIzaSyCmNH64ugLg5WxLqpuwx4RFm18H8JxZazc',
          clientId:
            '116798212930-jj8ecvnj65g23r6rk8q27dqlnbv5q1uu.apps.googleusercontent.com',
        },
        stackdriver: {
          apiKey: 'AIzaSyC2yhrEBW2nhO14J1-61lMGZ8QcZBU0r34',
          projectId: 'kakera-yoichi',
        },
      }

import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import signinImg from 'url:./images/btn_google_signin_light_normal_web.png'
import signinImg2x from 'url:./images/btn_google_signin_light_normal_web@2x.png'

export const SignIn = (props) => {
  const { onClick } = props

  return (
    <Button onClick={onClick} style={{ padding: 0 }}>
      <img
        alt="Sign in with Google"
        src={signinImg}
        srcset={signinImg + ' 1x, ' + signinImg2x + ' 2x'}
      />
    </Button>
  )
}

const FullScreenSignIn = (props) => {
  const { onClick } = props

  const style = makeStyles((theme) => ({
    signInContainer: {
      margin: 'calc(50vh - 60px) calc(50vw - 100px)',
    },
  }))()

  return (
    <div className={style.signInContainer}>
      <SignIn onClick={onClick} />
    </div>
  )
}

export default FullScreenSignIn

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  selected: null,
  editable: null,
  documentListUrl: '',
  syncStatus: 'not_changed',
  mobileEditing: false,
}

export const editorSlice = createSlice({
  initialState: initialState,
  name: 'editor',
  reducers: {
    enterEdit(state, action) {
      state.editable = action.payload.id
    },
    leaveEdit(state, action) {
      state.editable = null
    },
    select(state, action) {
      state.selected = action.payload.id
    },
    setDocumentListUrl(state, action) {
      state.documentListUrl = action.payload
    },
    setSyncStatus(state, action) {
      const status = action.payload
      if (
        ['unsynced', 'synced', 'syncing', 'error'].indexOf(action.payload) >= 0
      ) {
        state.syncStatus = status
      }
    },
    setMobileEditing(state, action) {
      state.mobileEditing = action.payload
    },
  },
})

import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

const Spinner = (props) => {
  const style = makeStyles((theme) => ({
    container: {
      width: '100vw',
      height: 'calc(100vh - 64px)',
    },
    progress: {
      margin: 'calc(50vh - 50px) calc(50vw - 20px)',
    },
  }))()

  return (
    <div className={style.container}>
      <CircularProgress className={style.progress} />
    </div>
  )
}
export default Spinner

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import CssBaseline from '@material-ui/core/CssBaseline'

import Welcome from './welcome'
import Index from './drive/documents/index'
import NewPage from './drive/documents/new_page'
import Edit from './drive/documents/edit'

import './error_reporting.js'

const app = (
  <BrowserRouter>
    <div>
      <CssBaseline />

      <Switch>
        <Route exact path="/drive/documents" component={Index} />
        <Route path="/drive/documents/new" component={NewPage} />
        <Route path="/drive/documents/:fileId" component={Edit} />
        <Route exact path="/" component={Welcome} />
      </Switch>
    </div>
  </BrowserRouter>
)

ReactDOM.render(app, document.querySelector('#app'))

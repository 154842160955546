import { createSlice } from '@reduxjs/toolkit'

/*
ID: {
  id,
  title,
  body,
  open,
  parent,
  children[]
},
...
*/
const initialState = {
  leaves: {},
  root: '',
  markup: 'plaintext',
  version: 0,
}

export const documentSlice = createSlice({
  initialState: initialState,
  name: 'document',
  reducers: {
    setInitialDocument(state, action) {
      const payload = action.payload
      // root
      state.root = payload.id
      state.leaves[payload.id] = {
        id: payload.id,
        title: payload.title,
        body: payload.body,
        parent: null,
        open: true,
        children: payload.children.map((c) => c.id),
      }

      // children
      const setChildren = (parent, state) => {
        parent.children.map((c) => {
          state.leaves[c.id] = {
            id: c.id,
            title: c.title,
            body: c.body,
            parent: parent.id,
            open: c.open,
            children: c.children.map((c) => c.id),
          }

          setChildren(c, state)
        })
      }
      setChildren(payload, state)

      // metadata
      state.markup = payload.markup
      state.version = payload.version
    },
    add(state, action) {
      const { parentId, position, child } = action.payload
      const parent = state.leaves[parentId]
      state.leaves[child.id] = { ...child, parent: parentId }
      parent.children.splice(position, 0, child.id)
    },
    update(state, action) {
      const { id, values } = action.payload
      const target = state.leaves[id]
      state.leaves[id] = { ...target, ...values }
    },
    updateMeta(state, action) {
      const payload = action.payload
      Object.keys(payload).map((k) => (state[k] = payload[k]))
    },
    move(state, action) {
      const { id, destinationId, position } = action.payload
      const target = state.leaves[id]
      const currentParent = state.leaves[target.parent]
      const destination = state.leaves[destinationId]
      // 古い親の子リストから抜く
      currentParent.children = currentParent.children.filter((i) => i != id)
      // 親を付け替え
      state.leaves[id] = { ...target, parent: destinationId }
      // 新しい親の子リストに挿入
      destination.children.splice(position, 0, id)
    },
    remove(state, action) {
      const id = action.payload.id
      const target = state.leaves[id]
      const parent = state.leaves[target.parent]
      // 親から抜く
      parent.children = parent.children.filter((i) => i != id)
      // ターゲットの子孫を列挙
      const ids = (l) => {
        return [l.children, l.children.map((c) => ids(state.leaves[c]))].flat(3)
      }
      // ターゲットの子孫を除去
      ids(target).map((i) => delete state.leaves[i])
      // ターゲット自体を除去
      delete state.leaves[id]
    },
  },
})

export const storeToDocument = (store) => {
  const { root, markup, version, leaves } = store
  const rootLeaf = leaves[root]

  const f = (leaf) => {
    const children = leaf.children.map((cid) => f(leaves[cid]))
    return {
      id: leaf.id,
      title: leaf.title,
      body: leaf.body,
      children: children,
      open: leaf.open,
    }
  }

  return { ...f(rootLeaf), ...{ markup, version, schema_version: 2 } }
}

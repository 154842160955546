import React from 'react'

import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

const SettingsDialog = (props) => {
  const { open, onClose } = props
  const clicked = (v) => {
    onClose(v)
  }

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle id="simple-dialog-title">使用する記法</DialogTitle>
      <List>
        <ListItem autoFocus button onClick={() => clicked('plaintext')}>
          <ListItemText primary="プレーンテキスト" />
        </ListItem>
        <ListItem autoFocus button onClick={() => clicked('hatena')}>
          <ListItemText primary="はてな記法" />
        </ListItem>
        <ListItem autoFocus button onClick={() => clicked('markdown')}>
          <ListItemText primary="マークダウン" />
        </ListItem>
      </List>
    </Dialog>
  )
}
export default SettingsDialog

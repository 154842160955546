import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Divider from '@material-ui/core/Divider'

import { documentSlice } from '../slice/documentSlice'
import { editorSlice } from '../slice/editorSlice'

import Presenter from './Presenter'
import EditForm from './EditForm'

const ContentBody = (props) => {
  const { chapterPrefix, chapter, id, leaf, parent, rootId, markup, editable } =
    props

  const dispatch = useDispatch()

  const { update } = documentSlice.actions
  const { enterEdit, leaveEdit, select } = editorSlice.actions

  const onClick = () => {
    dispatch(enterEdit({ id: leaf.id }))
    dispatch(select({ id: leaf.id }))
  }

  return (
    <li className="chapter" name={'content-' + leaf.id}>
      {leaf.id != editable && (
        <div onClick={onClick} className="content" aria-label="content">
          <h2 aria-label="content-title">
            {parent && chapter} {leaf.title}
          </h2>
          <Presenter markup={markup} value={leaf.body} />
        </div>
      )}

      {leaf.id == editable && (
        <EditForm
          title={leaf.title}
          body={leaf.body}
          onSubmit={(values) =>
            dispatch(update({ id: leaf.id, values: values }))
          }
          onClose={() => dispatch(leaveEdit())}
        />
      )}

      <Divider />

      {leaf.children.length > 0 && (
        <ol>
          {leaf.children.map((cid) => (
            <Content chapterPrefix={chapter} id={cid} key={cid} />
          ))}
        </ol>
      )}
    </li>
  )
}

const ContentBodyMemo = React.memo(ContentBody)

const Content = (props) => {
  const { chapterPrefix, id } = props

  const leaf = useSelector((s) => s.document.leaves[id])
  const parent = useSelector((s) => s.document.leaves[leaf.parent])
  const rootId = useSelector((s) => s.document.root)
  const markup = useSelector((s) => s.document.markup)
  const editable = useSelector((s) => s.editor.editable)

  const currentPosition = parent ? parent.children.indexOf(leaf.id) : 0
  let chapter = currentPosition + 1
  if (chapterPrefix != '' && parent.id != rootId) {
    chapter = chapterPrefix + '.' + (currentPosition + 1)
  }

  return (
    <ContentBodyMemo
      chapterPrefix={chapterPrefix}
      chapter={chapter}
      id={id}
      leaf={leaf}
      parent={parent}
      rootId={rootId}
      markup={markup}
      editable={editable}
    />
  )
}
export default Content

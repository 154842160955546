import React from 'react'
import DOMPurify from 'dompurify'
import Hatena from '../../../../../../vendor/text-hatena.js/text-hatena.js'
import parse from 'html-react-parser'
import Markdown from 'react-markdown'

const PlainText = (props) => {
  const { value } = props

  const text = value.replace(/\n/g, '<br />')
  const renderedText = parse(DOMPurify.sanitize(text))

  return <div className="plaintext body">{renderedText}</div>
}

const TextHatena = (props) => {
  const { value } = props

  const parser = new Hatena({ sectionanchor: '■' })
  const rendered = parse(DOMPurify.sanitize(parser.parse(value)))

  return <div className="hatena body">{rendered}</div>
}

const Md = (props) => {
  const { value } = props
  return (
    <div className="markdown body">
      <Markdown>{value}</Markdown>
    </div>
  )
}

const Presenter = (props) => {
  const { markup, value } = props

  return (
    <>
      {markup == 'hatena' && <TextHatena value={value} />}
      {markup == 'markdown' && <Md value={value} />}
      {markup != 'hatena' && markup != 'markdown' && (
        <PlainText value={value} />
      )}
    </>
  )
}
export default React.memo(Presenter)

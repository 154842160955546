import React, { useEffect, useState } from 'react'
import { Link, withRouter } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'

import { initAuth, authenticate } from './authentication.js'
import { SignIn } from './sign_in'

const Welcome = (props) => {
  const [authLoaded, setAuthLoaded] = useState(false)
  let [signedIn, setSignedIn] = useState(false)

  useEffect(async () => {
    setSignedIn(await initAuth())
    setAuthLoaded(true)
  })

  return (
    <div>
      {!signedIn && authLoaded && (
        <SignIn
          onClick={async () => {
            if (await authenticate()) {
              setSignedIn(true)
              location.href = '/drive/documents'
            }
          }}
        />
      )}
      {signedIn && (
        <Button
          variant="contained"
          startIcon={<Icon>library_books</Icon>}
          onClick={() => {
            location.href = '/drive/documents'
          }}
        >
          文書一覧
        </Button>
      )}
    </div>
  )
}

export default withRouter(Welcome)

import React, { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import './editor/stylesheets/index.scss'

import { store } from './editor/javascripts/slice/store'
import {
  documentSlice,
  storeToDocument,
} from './editor/javascripts/slice/documentSlice'
import { editorSlice } from './editor/javascripts/slice/editorSlice'

import {
  bufferedWrite,
  saveTimerStart,
  isCurrentVersionValid,
} from './editor/javascripts/accessor/yoichi'
import { getFile } from './editor/javascripts/models/file.js'
import { initAuth, authenticate, currentToken } from '../../authentication.js'

import Editor from './editor/javascripts/Editor'
import Overlay from './editor/javascripts/overlay'
import FullScreenSignIn from '../../sign_in'
import Spinner from '../../spinner.jsx'

const Edit = (props) => {
  const [authLoaded, setAuthLoaded] = useState(false)
  const [signedIn, setSignedIn] = useState(false)
  const [docLoaded, setDocLoaded] = useState(false)

  const { fileId } = props.match.params

  const { setSyncStatus, setDocumentListUrl, select } = editorSlice.actions
  const { setInitialDocument } = documentSlice.actions

  const onConflict = () => {
    store.dispatch(setSyncStatus('error'))
    if (location.hostname !== 'localhost') {
      location.reload()
    }
  }

  // 左上クローズボタンでの戻り先
  store.dispatch(setDocumentListUrl('../documents'))

  useEffect(async () => {
    // 認証状態を取得
    const isSignedIn = await initAuth()
    setAuthLoaded(true)
    setSignedIn(isSignedIn)

    store.dispatch(setSyncStatus('error'))
  }, [])

  useEffect(async () => {
    if (signedIn) {
      // 読み込み実行
      const doc = await getFile(fileId)
      bufferedWrite(doc)

      document.title = doc.title + ': カケラの樹'
      store.dispatch(setInitialDocument(doc))

      const leafIds = [
        ...Object.keys(store.getState().document?.leaves),
        doc.id,
      ]
      const selectedId = store.getState().editor?.selected
      // 選択済みのIDがこの文書中に存在するならルートノードを選択しない
      if (!leafIds.includes(selectedId)) store.dispatch(select({ id: doc.id }))
      store.dispatch(setSyncStatus('synced'))
      setDocLoaded(true)

      saveTimerStart({
        fileId,
        onWrite: () => {
          store.dispatch(setSyncStatus('syncing'))
        },
        onWrote: () => {
          store.dispatch(setSyncStatus('synced'))
        },
        onConflict: onConflict,
      })

      store.subscribe(() => {
        const state = store.getState()
        if (bufferedWrite(storeToDocument(state.document))) {
          store.dispatch(setSyncStatus('unsynced'))
        }
      })
    }
  }, [signedIn])

  // ウィンドウフォーカス時のバージョンチェック
  const checkVersion = async () => {
    if (currentToken()) {
      store.dispatch(setSyncStatus('syncing'))

      if (await isCurrentVersionValid(fileId)) {
        store.dispatch(setSyncStatus('synced'))
        return true // trueを返すとOverlayが消え編集可能に
      } else {
        onConflict()
        return false
      }
    } else {
      // トークンがexpireしているので未認証状態に戻す
      setSignedIn(false)
    }
  }

  return (
    <div>
      {signedIn && docLoaded && (
        <div>
          <Provider store={store}>
            <Editor />
          </Provider>

          <Overlay onFocus={checkVersion} />
        </div>
      )}

      {authLoaded && !signedIn && (
        <FullScreenSignIn
          onClick={async () => {
            if (await authenticate()) setSignedIn(true)
          }}
        />
      )}

      {(!authLoaded || !docLoaded) && <Spinner />}
    </div>
  )
}
export default Edit

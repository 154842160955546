import { googleApiCredentials } from './credentials.js'
import Cookies from 'js-cookie'
const TOKEN_KEY = 'kakera-token'

let requestAccessToken

export const currentToken = () => JSON.parse(Cookies.get(TOKEN_KEY) || 'null')

export const initAuth = async () => {
  await new Promise((callback, onerror) =>
    gapi.load('client', { callback, onerror })
  )

  await gapi.client.init({
    apiKey: googleApiCredentials.drive.apiKey,
    discoveryDocs: [
      'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
    ],
  })

  const token = currentToken()
  if (token) gapi.client.setToken(token)

  requestAccessToken = (prompt = '') => {
    let tokenClient
    const promise = new Promise((resolve, reject) => {
      tokenClient = google.accounts.oauth2.initTokenClient({
        client_id: googleApiCredentials.drive.clientId,
        scope:
          'https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.install',
        callback: (t) => {
          Cookies.set(TOKEN_KEY, JSON.stringify(t), {
            expires: (t['expires_in'] - 20) / (24 * 60 * 60),
          })
          resolve(t)
        },
      })
    })

    tokenClient.requestAccessToken({ prompt })

    return promise
  }

  return !(gapi.client.getToken() === null)
}

export const authenticate = async () => {
  return await requestAccessToken()
}

export const signOut = () => {
  Cookies.remove(TOKEN_KEY)
  const cred = gapi.client.getToken()
  if (cred !== null) {
    gapi.client.setToken('')
  }
}

export const refreshToken = async () => {
  if (!currentToken()) await authenticate()
}

let viewer
const fetchViewer = async () => {
  const r = await fetch('/viewer.template.html')
  const t = await r.text()
  const parser = new DOMParser()
  const v = parser.parseFromString(t, 'text/html')
  if (!v.querySelector('#document-body')) {
    throw 'broken viewer template!'
  }

  return v
}

export const getFile = async (fileId) => {
  const r = await gapi.client.drive.files.get({
    fileId,
    alt: 'media',
  })

  const parser = new DOMParser()
  const doc = parser.parseFromString(r.body, 'text/html')
  const body = JSON.parse(doc.querySelector('#document-body').value)

  return body
}

export const getMetadata = async (fileId, fields = 'appProperties') => {
  const r = await gapi.client.drive.files.get({
    fileId,
    fields,
  })

  return r.result
}

export const save = async (document, fileId = null, metadata = {}) => {
  if (!viewer) viewer = await fetchViewer()
  document.schema_version = 2

  // 文書をviewer htmlに包む
  viewer.querySelector('#document-body').value = JSON.stringify(document)

  metadata.name = document.title
  metadata.appProperties = {
    version: document.version,
  }

  const payload = new FormData()
  payload.append(
    'metadata',
    new Blob([JSON.stringify(metadata)], { type: 'application/json' })
  )
  payload.append(
    'media',
    new Blob([viewer.documentElement.outerHTML], { type: 'text/html' })
  )

  let endpoint =
    'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart'
  let method = 'POST'
  if (fileId) {
    endpoint =
      'https://www.googleapis.com/upload/drive/v3/files/' +
      fileId +
      '?uploadType=multipart'
    method = 'PATCH'
  }

  const r = await fetch(endpoint, {
    method: method,
    headers: new Headers({
      Authorization: 'Bearer ' + gapi.auth.getToken().access_token,
    }),
    body: payload,
  })

  if (r.ok) {
    const ret = await r.json()
    return ret
  } else {
    return null
  }
}

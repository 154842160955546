import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { scroller } from 'react-scroll'
import Paper from '@material-ui/core/Paper'

import Content from './Content'

const Contents = (props) => {
  const { id } = props

  const selected = useSelector((s) => s.editor.selected)

  useEffect(() => {
    if (selected) {
      const target = 'content-' + selected

      scroller.scrollTo(target, {
        duration: 500,
        delay: 100,
        offset: -20,
        smooth: true,
        containerId: 'contents-container',
      })
    }
  }, [selected])

  return (
    <div className="contents" id="contents-container">
      <div className="container">
        <Paper>
          <ol className="contents">
            <Content chapterPrefix="" id={id} />
          </ol>
        </Paper>
      </div>
    </div>
  )
}
export default Contents

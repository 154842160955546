import React from 'react'

import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'

const HelpDialog = (props) => {
  const { open, onClose } = props

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>ヘルプ</DialogTitle>
      <div>
        <h2>使用できるキーボード・ショートカット</h2>
        <table>
          <tbody>
            <tr>
              <th>前の葉に移動</th>
              <td>↑</td>
            </tr>
            <tr>
              <th>次の葉に移動</th>
              <td>↓</td>
            </tr>
            <tr>
              <th>葉を追加</th>
              <td>CTRL+ALT+N</td>
            </tr>
            <tr>
              <th>葉を編集</th>
              <td>ENTER</td>
            </tr>
            <tr>
              <th>葉を開く</th>
              <td>→</td>
            </tr>
            <tr>
              <th>葉を閉じる</th>
              <td>←</td>
            </tr>
            <tr>
              <th>上の葉と入れ替える</th>
              <td>CTRL+↑</td>
            </tr>
            <tr>
              <th>下の葉と入れ替える</th>
              <td>CTRL+↓</td>
            </tr>
            <tr>
              <th>一段下げる</th>
              <td>CTRL+→</td>
            </tr>
            <tr>
              <th>一段上げる</th>
              <td>CTRL+←</td>
            </tr>
            <tr>
              <th>葉を削除</th>
              <td>CTRL+DEL</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Dialog>
  )
}

export default HelpDialog

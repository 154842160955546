import React, { useEffect, useRef } from 'react'
import { refreshToken } from '../../../../../authentication.js'

import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'

const EditForm = (props) => {
  const { title, body, onSubmit, onClose } = props
  const titleRef = useRef()
  const bodyRef = useRef()

  const submit = async () => {
    await refreshToken()
    onSubmit({ title: titleRef.current.value, body: bodyRef.current.value })
    onClose()
  }

  useEffect(() => {
    titleRef.current.focus()
  }, [])

  return (
    <div>
      <div>
        <TextField
          label="タイトル"
          aria-label="title-input"
          defaultValue={title}
          inputRef={titleRef}
          fullWidth
        />
      </div>

      <div>
        <TextField
          label="本文"
          aria-label="body-input"
          defaultValue={body}
          multiline
          inputRef={bodyRef}
          fullWidth
        />
      </div>

      <div className="submit">
        <IconButton aria-label="cancel" onClick={onClose}>
          <Icon>clear</Icon>
        </IconButton>

        <IconButton aria-label="submit" onClick={submit}>
          <Icon>done</Icon>
        </IconButton>
      </div>
    </div>
  )
}
export default EditForm

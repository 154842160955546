import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

const Overlay = (props) => {
  const [focused, setFocused] = useState(true)
  const { onFocus } = props

  const style = makeStyles((theme) => ({
    overlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      zIndex: 1000,
    },
  }))()

  useEffect(() => {
    window.addEventListener(
      'blur',
      () => {
        setFocused(false)
      },
      false
    )
    window.addEventListener(
      'focus',
      () => {
        if (onFocus()) setFocused(true)
      },
      false
    )
  }, [])

  return <div>{!focused && <div className={style.overlay}></div>}</div>
}
export default Overlay

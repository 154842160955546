import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import { v4 as uuidv4 } from 'uuid'

import { documentSlice } from '../slice/documentSlice'
import { editorSlice } from '../slice/editorSlice'

const LeafBody = (props) => {
  const {
    chapterPrefix,
    id,
    leaf,
    parent,
    parentOfParent,
    selected,
    elderBrother,
  } = props

  const { add, update, move, remove } = documentSlice.actions
  const { select, setMobileEditing } = editorSlice.actions

  const dispatch = useDispatch()

  const currentPosition = parent.children.indexOf(leaf.id)
  let chapter = currentPosition + 1
  if (chapterPrefix != '') {
    chapter = chapterPrefix + '.' + (currentPosition + 1)
  }

  const onTitleClick = () => {
    if (selected) {
      dispatch(setMobileEditing(true))
    } else {
      dispatch(select({ id: leaf.id }))
    }
  }

  const onToUpClick = () => {
    const parentPosition = parentOfParent.children.indexOf(parent.id)

    dispatch(
      move({
        id: leaf.id,
        destinationId: parent.parent,
        position: parentPosition + 1,
      })
    )
  }

  const onToElderClick = () => {
    dispatch(
      move({
        id: leaf.id,
        destinationId: parent.id,
        position: currentPosition - 1,
      })
    )
  }

  const onToDownClick = () => {
    dispatch(
      move({
        id: leaf.id,
        destinationId: elderBrother.id,
        position: elderBrother.children.length,
      })
    )
  }

  const onToYoungerClick = () => {
    dispatch(
      move({
        id: leaf.id,
        destinationId: parent.id,
        position: currentPosition + 1,
      })
    )
  }

  const onAddClick = () => {
    dispatch(
      add({
        parentId: leaf.id,
        position: 0,
        child: {
          id: uuidv4(),
          title: '',
          body: '',
          parent: leaf.id,
          open: true,
          children: [],
        },
      })
    )
  }

  const klass = selected ? 'selected' : ''

  return (
    <li className={klass} aria-label="leaf-container" name={'leaf-' + leaf.id}>
      <div className="leaf_body">
        {leaf.open && (
          <IconButton
            aria-label="collapse-button"
            onClick={() => {
              dispatch(update({ id: leaf.id, values: { open: false } }))
            }}
          >
            <Icon>expand_less</Icon>
          </IconButton>
        )}
        {!leaf.open && (
          <IconButton
            aria-label="expand-button"
            onClick={() => {
              dispatch(update({ id: leaf.id, values: { open: true } }))
            }}
          >
            <Icon>expand_more</Icon>
          </IconButton>
        )}

        <span aria-label="leaf-title" onClick={onTitleClick}>
          {chapter} {leaf.title}
        </span>
      </div>

      {selected && (
        <div className="buttons">
          <IconButton
            aria-label="remove-button"
            onClick={() => {
              dispatch(remove({ id: leaf.id }))
            }}
          >
            <Icon>delete</Icon>
          </IconButton>

          {parent.parent != null && (
            <IconButton aria-label="to-up-button" onClick={onToUpClick}>
              <Icon>arrow_left</Icon>
            </IconButton>
          )}

          {currentPosition > 0 && (
            <IconButton aria-label="to-elder-button" onClick={onToElderClick}>
              <Icon>arrow_drop_up</Icon>
            </IconButton>
          )}

          {currentPosition > 0 && (
            <IconButton aria-label="to-down-button" onClick={onToDownClick}>
              <Icon>arrow_right</Icon>
            </IconButton>
          )}

          {currentPosition < parent.children.length - 1 && (
            <IconButton
              aria-label="to-younger-button"
              onClick={onToYoungerClick}
            >
              <Icon>arrow_drop_down</Icon>
            </IconButton>
          )}

          <IconButton aria-label="add-button" onClick={onAddClick}>
            <Icon>add</Icon>
          </IconButton>
        </div>
      )}

      {leaf.open && (
        <ol>
          {leaf.children.map((cid) => (
            <Leaf chapterPrefix={chapter} id={cid} key={cid} />
          ))}
        </ol>
      )}
    </li>
  )
}

const LeafBodyMemo = React.memo(LeafBody)

const Leaf = (props) => {
  const { chapterPrefix, id } = props
  const leaf = useSelector((s) => s.document.leaves[id])
  const parent = useSelector((s) => s.document.leaves[leaf.parent])
  const parentOfParent = useSelector((s) => s.document.leaves[parent.parent])
  const selectedId = useSelector((s) => s.editor.selected)

  const currentPosition = parent.children.indexOf(leaf.id)
  const elderBrother = useSelector(
    (s) => s.document.leaves[parent.children[currentPosition - 1]]
  )

  return (
    <LeafBodyMemo
      chapterPrefix={chapterPrefix}
      id={id}
      leaf={leaf}
      parent={parent}
      parentOfParent={parentOfParent}
      selected={selectedId == leaf.id}
      elderBrother={elderBrother}
    />
  )
}

export default Leaf

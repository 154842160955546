import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { scroller } from 'react-scroll'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import { v4 as uuidv4 } from 'uuid'

import { documentSlice } from '../slice/documentSlice'
import { editorSlice } from '../slice/editorSlice'

import Leaf from './Leaf'

const Tree = (props) => {
  const { id } = props
  const root = useSelector((s) => s.document.leaves[id])
  const selected = useSelector((s) => s.editor.selected)
  const dispatch = useDispatch()
  const { add } = documentSlice.actions
  const { select, setMobileEditing } = editorSlice.actions

  const klass = root.id == selected ? 'root-selected' : ''

  useEffect(() => {
    // 自動スクロール処理
    if (selected) {
      scroller.scrollTo('leaf-' + selected, {
        duration: 500,
        delay: 100,
        offset: -200,
        smooth: true,
        containerId: 'tree-container',
      })
    }
  }, [selected])

  const onTitleClick = () => {
    if (root.id == selected) {
      dispatch(setMobileEditing(true))
    } else {
      dispatch(select({ id: root.id }))
    }
  }

  const onAddClick = () => {
    dispatch(
      add({
        parentId: root.id,
        position: 0,
        child: {
          id: uuidv4(),
          title: '',
          body: '',
          parent: root.id,
          open: true,
          children: [],
        },
      })
    )
  }

  return (
    <div className="tree" id="tree-container">
      <div className="container">
        <Paper>
          <div
            className={klass}
            name={'leaf-' + root.id}
            aria-label="root-leaf"
          >
            <div className="root-title">
              <span onClick={onTitleClick} aria-label="root-title">
                {root.title}
              </span>
            </div>
            {root.id == selected && (
              <div className="buttons">
                <IconButton onClick={onAddClick} aria-label="add-button">
                  <Icon>add</Icon>
                </IconButton>
              </div>
            )}

            <ol>
              {root.children.map((cid) => (
                <Leaf chapterPrefix="" id={cid} key={cid} />
              ))}
            </ol>
          </div>
        </Paper>
      </div>
    </div>
  )
}
export default Tree

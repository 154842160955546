import React, { useEffect, useRef } from 'react'
import Hotkeys from 'react-hot-keys'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import { documentSlice } from './slice/documentSlice'
import { editorSlice } from './slice/editorSlice'

const EditorHotkeys = (props) => {
  const { selected } = props

  const rootId = useSelector((s) => s.document.root)
  const leaf = useSelector((s) => s.document.leaves[selected])
  const parent = useSelector((s) => leaf && s.document.leaves[leaf.parent])
  const leaves = useSelector((s) => s.document.leaves)

  const { add, update, move, remove } = documentSlice.actions
  const { select, enterEdit } = editorSlice.actions

  const dispatch = useDispatch()

  const onKeyDown = (keyName, e, handle) => {
    e.preventDefault()

    switch (keyName) {
      case 'up':
        if (leaf && parent) {
          const position = parent.children.indexOf(leaf.id)
          if (position == 0) {
            // 自分は先頭の子なので親へ移動
            dispatch(select({ id: parent.id }))
          } else {
            const f = (t) => {
              if (t.children.length == 0 || !t.open) {
                return t
              } else {
                return f(leaves[t.children[t.children.length - 1]])
              }
            }
            // 前の葉のツリーの末端へ移動
            const t = f(leaves[parent.children[position - 1]])
            dispatch(select({ id: t.id }))
          }
        } else {
          if (leaf) {
            // 最後の葉を探索
            const f = (t) => {
              if (t.children.length > 0) {
                return f(leaves[t.children[t.children.length - 1]])
              } else {
                return t
              }
            }
            dispatch(select({ id: f(leaf).id }))
          } else {
            dispatch(select({ id: rootId }))
          }
        }

        break

      case 'down':
        if (leaf) {
          if (leaf.children.length > 0 && leaf.open) {
            // 子が存在する場合、先頭の子へ
            dispatch(select({ id: leaf.children[0] }))
          } else {
            // 子が存在しない場合
            const position = parent.children.indexOf(leaf.id)
            if (position < parent.children.length - 1) {
              // 同一階層の次の葉
              dispatch(select({ id: parent.children[position + 1] }))
            } else {
              // 上の階層の次の葉を探す
              const f = (t) => {
                // 自分の次の葉を返す
                // 存在しない場合親のものを返す
                const parent = leaves[t.parent]
                const position = parent.children.indexOf(t.id)
                if (position < parent.children.length - 1) {
                  return parent.children[position + 1]
                } else {
                  return f(parent)
                }
              }

              try {
                const nextId = f(leaf)
                dispatch(select({ id: nextId }))
              } catch {
                dispatch(select({ id: rootId }))
              }
            }
          }
        } else {
          dispatch(select({ id: rootId }))
        }
        break

      case 'left':
        dispatch(update({ id: leaf.id, values: { open: false } }))
        break

      case 'right':
        dispatch(update({ id: leaf.id, values: { open: true } }))
        break

      case 'enter':
        dispatch(enterEdit({ id: leaf.id }))
        break

      case 'ctrl+left':
        if (parent.id != rootId) {
          const pOP = leaves[parent.parent]
          const pPos = pOP.children.indexOf(parent.id)
          dispatch(
            move({
              id: leaf.id,
              destinationId: pOP.id,
              position: pPos + 1,
            })
          )
        }
        break

      case 'ctrl+right':
        let position = parent.children.indexOf(leaf.id)
        if (position > 0) {
          const elderBrother = leaves[parent.children[position - 1]]
          dispatch(
            move({
              id: leaf.id,
              destinationId: elderBrother.id,
              position: elderBrother.children.length,
            })
          )
        }
        break

      case 'ctrl+up':
        position = parent.children.indexOf(leaf.id)
        if (position > 0) {
          dispatch(
            move({
              id: leaf.id,
              destinationId: parent.id,
              position: position - 1,
            })
          )
        }
        break

      case 'ctrl+down':
        position = parent.children.indexOf(leaf.id)
        if (position < parent.children.length - 1) {
          dispatch(
            move({
              id: leaf.id,
              destinationId: parent.id,
              position: position + 1,
            })
          )
        }
        break

      case 'ctrl+alt+n':
        dispatch(
          add({
            parentId: leaf.id,
            position: 0,
            child: {
              id: uuidv4(),
              title: '',
              body: '',
              parent: leaf.id,
              open: true,
              children: [],
            },
          })
        )
        break

      case 'ctrl+del':
        if (parent) {
          dispatch(remove({ id: leaf.id }))
          dispatch(select({ id: parent.id }))
        }
        break
    }
  }

  return (
    <Hotkeys
      keyName="up, down, left, right, enter, ctrl+left, ctrl+right, ctrl+up, ctrl+down, ctrl+alt+n, ctrl+del"
      onKeyDown={onKeyDown}
      filter={(e) => {
        const tagName = (e.target || e.srcElement).tagName
        const tags = ['INPUT', 'SELECT', 'TEXTAREA', 'BUTTON']
        return !(tagName.isContentEditable || tags.indexOf(tagName) >= 0)
      }}
    />
  )
}
export default EditorHotkeys

import React, { useEffect, useState } from 'react'
import queryString from 'query-string'
import { v4 as uuidv4 } from 'uuid'
import { initAuth, authenticate } from '../../authentication.js'
import { save } from './editor/javascripts/models/file'
import Spinner from '../../spinner.jsx'
import FullScreenSignIn from '../../sign_in'

const NewPage = (props) => {
  const [authLoaded, setAuthLoaded] = useState(false)
  const [signedIn, setSignedIn] = useState(false)

  // useEffect(() => initAuth(updateSignedInStatus), [])
  useEffect(async () => {
    setSignedIn(await initAuth())
    setAuthLoaded(true)
  })

  useEffect(async () => {
    if (signedIn) {
      const document = {
        id: uuidv4(),
        title: '(新しい文書)',
        body: '',
        children: [],
        markup: 'plaintext',
      }

      document.version = new Date().valueOf()

      const metadata = {}
      const query = queryString.parse(location.search)
      if (query.state) {
        const state = JSON.parse(query.state)
        metadata.parents = [state.folderId]
      }

      const r = await save(document, null, metadata)
      location.href = './' + r.id
    }
  }, [signedIn])

  return (
    <div>
      {!signedIn && authLoaded && (
        <FullScreenSignIn
          onClick={async () => {
            if (await authenticate()) setSignedIn(true)
          }}
        />
      )}
      {(signedIn || !authLoaded) && <Spinner />}
    </div>
  )
}

export default NewPage

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined'
import CloudDoneOutlinedIcon from '@material-ui/icons/CloudDoneOutlined'
import SyncProblemOutlinedIcon from '@material-ui/icons/SyncProblemOutlined'

import { documentSlice } from './slice/documentSlice'
import { editorSlice } from './slice/editorSlice'

import Tree from './tree/Tree'
import Contents from './contents/Contents'
import SettingsDialog from './settings_dialog'
import HelpDialog from './help_dialog'
import EditorHotkeys from './EditorHotkeys'

const Editor = (props) => {
  const mobileEditing = useSelector((s) => s.editor.mobileEditing)
  const documentListUrl = useSelector((s) => s.editor.documentListUrl)
  const syncStatus = useSelector((s) => s.editor.syncStatus)

  const root = useSelector((s) => s.document.leaves[s.document.root])
  const selected = useSelector((s) => s.editor.selected)

  const dispatch = useDispatch()
  const { setMobileEditing } = editorSlice.actions
  const { updateMeta } = documentSlice.actions

  const [state, setState] = useState({ help: false, settings: false })

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <AppBar position="fixed">
            <Toolbar>
              <div className="title">
                {!mobileEditing && (
                  <IconButton onClick={() => (location.href = documentListUrl)}>
                    <Icon>close</Icon>
                  </IconButton>
                )}

                {mobileEditing && (
                  <IconButton
                    onClick={() => {
                      dispatch(setMobileEditing(false))
                    }}
                  >
                    <Icon>arrow_back</Icon>
                  </IconButton>
                )}

                <Typography variant="h6" noWrap>
                  {root.title}
                </Typography>

                {syncStatus == 'unsynced' && <CloudUploadOutlinedIcon />}
                {syncStatus == 'syncing' && <CircularProgress />}
                {syncStatus == 'synced' && <CloudDoneOutlinedIcon />}
                {syncStatus == 'error' && <SyncProblemOutlinedIcon />}
              </div>

              <div>
                <IconButton
                  onClick={() => setState({ ...state, ...{ help: true } })}
                >
                  <Icon>help_outline</Icon>
                </IconButton>
                <IconButton
                  onClick={() => setState({ ...state, ...{ settings: true } })}
                >
                  <Icon>settings</Icon>
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
        </Grid>

        <Hidden smUp>
          {!mobileEditing && (
            <Grid item xs={12}>
              <Tree id={root.id} />
            </Grid>
          )}

          {mobileEditing && (
            <Grid item xs={12}>
              <Contents id={root.id} />
            </Grid>
          )}
        </Hidden>

        <Hidden xsDown>
          <Grid item xs={6}>
            <Tree id={root.id} />
          </Grid>

          <Grid item xs={6}>
            <Contents id={root.id} />
          </Grid>
        </Hidden>
      </Grid>

      <SettingsDialog
        open={state.settings}
        onClose={(m) => {
          setState({ ...state, ...{ settings: false } })
          if (typeof m != 'undefined') {
            dispatch(updateMeta({ markup: m }))
          }
        }}
      />
      <HelpDialog
        open={state.help}
        onClose={() => {
          setState({ ...state, ...{ help: false } })
        }}
      />

      <EditorHotkeys selected={selected} />
    </div>
  )
}
export default Editor
